@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.main-header {
  text-align: center;
}

.main-header .logged-nav {
  display: inline-block;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: auto 2%;
}

.main-header nav {
  width: 90%;
  text-align: center;
  margin: auto;
}

.main-header nav a {
  padding-right: 1%;
  text-decoration: none;
}

.main-header h1 {
  font-family: 'Fjalla One';
  margin-bottom: 0;
}

.main-header h2 {
  margin-top: 0;
  font-weight: 500;
  font-size: 16px;
}
.form-area *,
.form-area {
  background: none;
}

.form-area {
  background-color: rgb(244, 241, 248);
  width: 92%;
  margin: auto;
  margin-top: 6%;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(14,51,76,.25);
}

.form-area p {
  color: rgb(24, 23, 23);
  text-align: left;
  padding: 5%;
  font-size: 15px;
  margin-bottom: 0;
}

.form-area h2 {
  display: inline-block;
  width: 90%;
  text-align: left;
  color: rgb(24, 23, 23);
  margin-top: 0;
}
.Label {
  text-align: left;
  display: inline-block;
  width: 90%;
  font-size: 12px;
  font-weight: 500;
  color: rgb(24, 23, 23);
  margin: 1%;
}
.Input {
  width: 90%;
  height: 3vh;
  font-size: 16px;
  padding: 2%;
  color: rgb(24, 23, 23);

  margin-bottom: 3%;

  border: 1px solid rgb(24, 23, 23);
  border-radius: 3px;
  outline: none;
}
.Input:focus {
  border: 1px solid #627b2f;
}
.Button {
  width: 94%;
  height: 6vh;
  font-size: 17px;
  font-weight: bold;
  margin-top: 4%;
  padding: 0;
  margin-bottom: 3%;
  border-radius: 3px;

  background-color: #627b2f;
  color: rgb(244, 241, 248);
  border: none;
  box-shadow: 0 4px 8px rgba(14,51,76,.252689);
}

.form-area a {
  color: rgb(24, 23, 23);
  margin: 4%;
  display: inline-block;
  font-size: 12px;
}
.login-area h2 {
  margin-top: 5%;
}
.dashboard-area {
  width: 92%;
  margin: auto;
}

.dashboard-info {
  box-shadow: 0 4px 8px rgba(14,51,76,.25);
  padding: 3%;
  margin: 10% auto;
}

.dashboard-info,
.dashboard-info * {
  background-color: rgb(244, 241, 248);
  color: rgb(24, 23, 23);
  border-radius: 3px;
}

.start-practicing {
  box-sizing: border-box;
  display: inline-block;
  width: 93%;
  height: 6vh;
  font-size: 17px;
  font-weight: bold;
  margin-top: 4%;
  padding: 3%;
  margin-bottom: 3%;
  border-radius: 3px;
  text-decoration: none;
  
  color: rgb(244, 241, 248);
  background-color: #627b2f;
  border: none;
  box-shadow: 0 4px 8px rgba(14,51,76,.252689);
}

.practice-list {
  list-style: none;
  padding: 0;
}

.dashboard-word {
  border: 1px solid rgb(244, 241, 248);
  background-color: rgb(30, 32, 37);
  box-shadow: 0 4px 8px rgba(37, 45, 49, 0.253);
  border-radius: 3px;
  margin: 5% auto;
  padding: 3%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.dashboard-word * {
  display: inline-block;
}

.dw-container {
  text-align: left;
  width: 50%;
}

.dw-container h4 {
  font-size: 20px;
  font-weight: 500;
  max-width: 50%;
  margin: 0;
}

.score-container {
  text-align: right;
  width: 50%;
}

.correct,
.incorrect {
  font-size: 16px;
  margin: 2%;
}

.correct {
  color: #abdb4c;
}

.incorrect {
  color: rgb(244, 241, 248);
}
.learn-section,
.learn-section * {
  color: rgb(24, 23, 23);
  border-radius: 3px;
}

.learn-section {
  background-color: rgb(244, 241, 248);
  width: 94%;
  margin: auto;
}

.learn-section button {
  color: rgb(244, 241, 248);
}
.App {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: 100vh;
  padding-top: 3%;

  background: rgb(32,32,32);
  background: -webkit-linear-gradient(237deg, rgba(32,32,32,1) 0%, rgba(20,20,29,1) 80%, rgba(17,19,23,1) 100%);
  background: linear-gradient(213deg, rgba(32,32,32,1) 0%, rgba(20,20,29,1) 80%, rgba(17,19,23,1) 100%);
}
* {
  color: rgb(244, 241, 248);
}

html {
  background-color: rgb(39, 39, 39);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 241, 248);
}

:root {
  --font-size: calc(10px + 1vmin);
}

main {
  text-align: center;
}





