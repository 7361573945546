@import url('https://fonts.googleapis.com/css?family=Ubuntu');

* {
  color: rgb(244, 241, 248);
}

html {
  background-color: rgb(39, 39, 39);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 241, 248);
}

:root {
  --font-size: calc(10px + 1vmin);
}

main {
  text-align: center;
}




