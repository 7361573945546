.dashboard-area {
  width: 92%;
  margin: auto;
}

.dashboard-info {
  box-shadow: 0 4px 8px rgba(14,51,76,.25);
  padding: 3%;
  margin: 10% auto;
}

.dashboard-info,
.dashboard-info * {
  background-color: rgb(244, 241, 248);
  color: rgb(24, 23, 23);
  border-radius: 3px;
}

.start-practicing {
  box-sizing: border-box;
  display: inline-block;
  width: 93%;
  height: 6vh;
  font-size: 17px;
  font-weight: bold;
  margin-top: 4%;
  padding: 3%;
  margin-bottom: 3%;
  border-radius: 3px;
  text-decoration: none;
  
  color: rgb(244, 241, 248);
  background-color: #627b2f;
  border: none;
  box-shadow: 0 4px 8px rgba(14,51,76,.252689);
}

.practice-list {
  list-style: none;
  padding: 0;
}

.dashboard-word {
  border: 1px solid rgb(244, 241, 248);
  background-color: rgb(30, 32, 37);
  box-shadow: 0 4px 8px rgba(37, 45, 49, 0.253);
  border-radius: 3px;
  margin: 5% auto;
  padding: 3%;
  display: flex;
  align-items: center;
}

.dashboard-word * {
  display: inline-block;
}

.dw-container {
  text-align: left;
  width: 50%;
}

.dw-container h4 {
  font-size: 20px;
  font-weight: 500;
  max-width: 50%;
  margin: 0;
}

.score-container {
  text-align: right;
  width: 50%;
}

.correct,
.incorrect {
  font-size: 16px;
  margin: 2%;
}

.correct {
  color: #abdb4c;
}

.incorrect {
  color: rgb(244, 241, 248);
}